@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");

* {
  box-sizing: border-box;
}

body,
html {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  background-color: #F4FAFF;
}

h1,
h2,
h3,
h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 0;

  letter-spacing: 1px
}

h1 {
  font-size: 70px;
  line-height: 70px !important;
  margin: 0 0 30px 0;
}

h2 {
  font-size: 50px;
  line-height: 60px;
  margin: 0 0 30px 0;
}

h3 {
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 10px 0;
}

h4 {
  font-size: 20px;
  line-height: 20px;
  margin: 0 0 5px 0;
}

p {
  line-height: 26px;
}

img {
  width: 100%;
}

// OVERIDES

section#intro,
section#fees {
  padding-bottom: 0;
}

@media screen and (max-width: 1400px) {
  h1 {
    font-size: 55px;
    line-height: 55px !important;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 35px;
    line-height: 35px !important;
  }

  h2 {
    font-size: 28px;
    line-height: 30px !important;
  }

  p {
    text-align: center;
  }
}
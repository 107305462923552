.alice-carousel__dots {
  position: absolute;
  bottom: 30px;
  width: 100%;
  margin: 0 !important;
}

.alice-carousel__dots-item {
  width: 14px;
  height: 14px;
  border: 1px solid #fff;
}

.alice-carousel__dots-item:hover,
.alice-carousel__dots-item.__active {
  background-color: #35375B;
}